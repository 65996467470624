import React from 'react';

import Layout from '../../components/Layout';
import { langPath } from '../../components/Nav';

import photo1 from '../../assets/images/house/Elegantfoto-5A.jpg';
import photo2 from '../../assets/images/house/Elegantfoto-7A.jpg';
import photo3 from '../../assets/images/house/Elegantfoto-13A.jpg';

const HouseProjectsPage = ({pageContext: {langKey}}) => (
  <Layout fullMenu {...{langKey}}>
    <section id="banner">
      <div className="inner">
        <h2>
          {langKey === 'en' && <>House projects</>}
          {langKey === 'vi' && <>Các dự án xây nhà</>}
        </h2>

        <a href={langPath(langKey, '/projects')} className="special">
          {langKey === 'en' && <>See other projects</>}
          {langKey === 'vi' && <>Xem các dự án khác</>}
        </a>
      </div>
    </section>

    <section id="wrapper">
      <div className="wrapper">
        <div className="inner">
          <img alt="" width="100%" src={photo1} />
          <img alt="" width="100%" src={photo2} />
          <img alt="" width="100%" src={photo3} />
        </div>
      </div>
    </section>
  </Layout>
);

export default HouseProjectsPage;
export { Head } from '../../components/Head';
